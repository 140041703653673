export const UserAction = (state: any, action: any) => ({
	...state,
	user: action.payload || {},
});

export const SingleUserAction = (state: any, action: any) => ({
	...state,
	singleUser: action.payload,
});

export const AddAnswerAction = (state: any, action: any) => ({
	...state,
	addAsnwer: action.payload,
});

export const GetQuestionAction = (state: any, action: any) => ({
	...state,
	getQuestion: action.payload,
});

export const GetAllReportsAction = (state: any, action: any) => ({
	...state,
	getAllReports: action?.payload || [],
});

export const GetAllReportsTypeAction = (state: any, action: any) => ({
	...state,
	getAllReportType: action?.payload || [],
});

export const GetReportAction = (state: any, action: any) => ({
	...state,
	getReport: action?.payload || [],
});

export const GetAllUserAction = (state: any, action: any) => ({
	...state,
	getAllUser: action?.payload || [],
});

export const GetStaticsAction = (state: any, action: any) => ({
	...state,
	statics: action?.payload || {},
});

export const GetMessageAction = (state: any, action: any) => ({
	...state,
	message: action?.payload || '',
});

export const GetStatusAction = (state: any, action: any) => ({
	...state,
	status: action?.payload,
});

export const SavedPerPageAction = (state: any, action: any) => ({
	...state,
	savedPerPage: action?.payload,
});

export const SavedPerAllReportsPageAction = (state: any, action: any) => ({
	...state,
	savedPerPageAllReports: action?.payload,
});

export const SavedPerUserReportsPageAction = (state: any, action: any) => ({
	...state,
	savedPerPageUserReports: action?.payload,
});

export const ReportTypeIdAction = (state: any, action: any) => ({
	...state,
	reportTypeId: action?.payload,
});

export const IsAdminLoginAction = (state: any, action: any) => ({
	...state,
	isAdminLogin: action?.payload,
});

export const LogoutAction = (state: any) => ({
	...state,
	user: {},
	addAsnwer: {},
	getQuestion: [],
	getAllReports: [],
	getReport: [],
	getAllReportType: [],
	savedPerPage: 5,
	savedPerPageAllReports: 5,
	savedPerPageUserReports: 5,
	reportTypeId: '',
	isAdminLogin: false,
});
