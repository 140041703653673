// th
export const userTableHeaderAllReports = ['Idea', 'Status', 'Language', 'Type', 'Created At', ' '];
export const adminTableHeaderAllReports  = [
	'Idea',
	'Status',
	'Tokens consumed',
	'Language',
	'Type',
	'Created At',
	' ',
];
export const adminTableHeader = [
	'Title',
	'Description',
	'Type',
	'Category',
	'Max Tokens',
	'Button Lable',
	'Order',
	'Model',
	' ',
];
export const adminAlluserTableHeader = [
	'First Name',
	'Last Name',
	'Email',
	'Plan',
	'Subscription_Date',
	'Role',
	'Active/Suspended',
	' ',
];

export const userDetailsTableHeader = ['IP', 'Duration', 'Login_time', ' '];

export const model: any = {
	GPT_TURBO: 'gpt-3.5-turbo',
	GPT_TURBO_16k: 'gpt-3.5-turbo-16k',
	DAVINCI_003: 'text-davinci-003',
	GPT_4: 'gpt-4',
};
