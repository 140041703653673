import { FC, useEffect, useState } from 'react';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import Icon from '../../icon/Icon';
import Button from '../../bootstrap/Button';
import PaginationButtons, { PER_COUNT, dataPagination } from '../../PaginationButtons';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import useSortableData from '../../../hooks/useSortableData';
import InputSearch from '../../inputSearch/InputSearch';
import { useDispatch, useSelector } from 'react-redux';
import { SAVED_PER_PAGE } from '../../../reducer/Types';

interface IAllUserTable {
	isFluid?: boolean;
	GetAllUserData?: any;
	heading?: string;
	tableHeader?: any;
	setCount?: any;
	count?: number;
	refetch?: any;
	hasMore?: boolean;
	ReportTypes?: any;
	setSearch?: any;
	search: {
		search: string;
	};
	searchUsers: any;
	searchUserLoading: boolean;
	dataCount: number;
	allUserDownload: any;
	loading: boolean;
}

const AllUserTable: FC<IAllUserTable> = ({
	GetAllUserData,
	isFluid,
	heading,
	tableHeader,
	setCount,
	count,
	refetch,
	hasMore,
	ReportTypes,
	setSearch,
	search,
	searchUsers,
	searchUserLoading,
	dataCount,
	allUserDownload,
	loading,
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const { savedPerPage } = useSelector((state: any) => state);
	const [perPage, setPerPage] = useState(savedPerPage || PER_COUNT['5']);
	const { items } = useSortableData(GetAllUserData || []);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: SAVED_PER_PAGE, payload: perPage });
	}, [perPage]);

	return (
		<Card stretch={isFluid}>
			<CardHeader borderSize={1}>
				<CardLabel icon='Person' iconColor='info'>
					<CardTitle>{heading}</CardTitle>
				</CardLabel>
				<div className='searchInput'>
					<InputSearch
						value={search.search}
						label='Search'
						name='search'
						onChange={(e: any) => setSearch({ search: e.target.value })}
						onClick={searchUsers}
						loading={searchUserLoading}
					/>
					<div className='cursor-pointer' onClick={allUserDownload}>
						<Icon icon='CloudDownload' size={'3x'} color='primary' />
					</div>
				</div>
			</CardHeader>
			<CardBody className='table-responsive' isScrollable={isFluid}>
				<table
					className='table table-modern'
					style={{ minHeight: !GetAllUserData?.length ? '300px' : '' }}>
					<thead>
						<tr>
							{tableHeader?.map((ele: any) => {
								return <th>{ele}</th>;
							})}
						</tr>
					</thead>
					<tbody>
						{dataPagination(items, currentPage, perPage).map((Ele: any) => (
							<tr key={Ele?.id}>
								<td className='table-idea' onClick={() => ReportTypes(Ele?.id)}>
									{Ele?.firstName}
								</td>
								<td className='table-idea' onClick={() => ReportTypes(Ele?.id)}>
									{Ele?.lastName}
								</td>
								<td className='table-idea' onClick={() => ReportTypes(Ele?.id)}>
									{Ele?.email}
								</td>
								<td className='table-idea' onClick={() => ReportTypes(Ele?.id)}>
									{Ele?.plan?.name || 'Not Selected'}
								</td>
								<td className='table-idea' onClick={() => ReportTypes(Ele?.id)}>
									{Ele?.subscriptionTime?.time || 'Not available'}
								</td>
								<td className='table-idea' onClick={() => ReportTypes(Ele?.id)}>
									{Ele?.role}
								</td>
								<td className='table-idea' onClick={() => ReportTypes(Ele?.id)}>
									{Ele.isSuspended ? (
										<span className='text-danger'>Suspended</span>
									) : (
										<span>Active</span>
									)}
								</td>
								<td className='table-drop'>
									<Dropdown className={'dropdownCustom'}>
										<DropdownToggle hasIcon={false}>
											<Icon
												icon='MoreVert'
												size={'lg'}
												className='cursor-pointer'
											/>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
											<DropdownItem>
												<Button
													className='table-idea'
													icon='RemoveRedEye'
													onClick={() => ReportTypes(Ele?.id)}>
													View
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</td>
							</tr>
						))}
						{!GetAllUserData?.length && (
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td className='display-6'>No record exists!</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						)}
					</tbody>
				</table>
			</CardBody>
			<PaginationButtons
				loading={loading}
				dataCount={dataCount}
				data={items}
				label='items'
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				perPage={savedPerPage}
				setPerPage={setPerPage}
				setCount={setCount}
				count={count}
				refetch={refetch}
				hasMore={hasMore}
			/>
		</Card>
	);
};

export default AllUserTable;
