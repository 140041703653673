export const LOGOUT = 'Logout';
export const ADDANSWER = 'AddAnswer';
export const GETQUESTION = 'GetQuestion';
export const GETALLREPORTS = 'GetAllReports';
export const GETREPORTS = 'GetReport';
export const USER = 'User';
export const SINGLEUSER = 'singleUser';
export const IDEA_LENGTH = 'IdeaLength';
export const GETALLREPORTSTYPE = 'getAllReportTypes';
export const MESSAGE = 'message';
export const STATUS = 'status';
export const GETALLUSER = 'getalluser';
export const STATICS = 'statics';
export const SAVED_PER_PAGE = 'savedPerPage';
export const SAVED_PER_PAGE_ALL_REPORTS = 'savedPerPageAllReports';
export const SAVED_PER_PAGE_USER_REPORTS = 'savedPerPageUserReports';
export const REPORTS_TYPE_ID = 'reportTypeId';
export const IS_ADMIN_LOGIN = 'isadminlogin';
