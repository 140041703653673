import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
// import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import RootReducer from '../reducer/Reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [
		'user',
		'addAsnwer',
		// 'getQuestion',
		'isAdminLogin',
		'reportTypeId',
		'getAllReports',
		'getReport',
		'idea',
		'getAllReportType',
		'getAllUser',
		'singleUser',
		'savedPerPage',
		'savedPerPageAllReports',
		'savedPerPageUserReports',
	], // which reducer want to store
};
const pReducer = persistReducer(persistConfig, RootReducer);

const store = createStore(pReducer, composeWithDevTools(applyMiddleware(thunk)));
const persistor = persistStore(store);
export { persistor, store };
