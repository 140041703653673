import classNames from 'classnames';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import useDarkMode from '../../../hooks/useDarkMode';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Logout } from '../../presentation/auth/Logout';
import { useMutation } from '@apollo/client';
import { LOGOUT, MANAGE_BILLING } from '../../../Api/Mutation';
import Spinner from '../../../components/bootstrap/Spinner';
import Button from '../../../components/bootstrap/Button';

const DashboardBookingHeader = () => {
	const dispatch = useDispatch();
	const { darkModeStatus } = useDarkMode();
	const { firstName, lastName, email, planId, role, sessionId, id, token } = useSelector(
		(state: any) => state?.user,
	);
	const [manageBilling, { loading }] = useMutation(MANAGE_BILLING);
	const [expireSession] = useMutation(LOGOUT);
	const navigate = useNavigate();

	const LogOutSession = async () => {
		const date = new Date();
		console.log(date.toISOString(), 'from booking header');

		try {
			if (user?.loggedInBy === 'user')
				await expireSession({
					variables: {
						logoutTime: date.toISOString(),
						sessionId: sessionId,
						userId: id,
					},
				});
		} catch (error) {
			console.log(error);
		}
	};

	const ManageBilling = () => {
		if (planId?.includes('0')) {
			navigate('/pricing');
		} else
			manageBilling({ variables: { email: email } })
				.then((res: any) => {
					const url = res?.data?.manageBilling?.url;
					if (!!res?.data?.manageBilling?.url) {
						window.location.href = url;
					}
				})
				.catch((err) => {
					console.log(err);
				});
	};

	const user: any = [
		{
			value: planId?.includes('0') || planId === null ? 'Upgrade Plan' : 'Manage Billing',
			function: () => ManageBilling(),
			icon: planId?.includes('0') || planId === null ? 'Upgrade' : 'ManageAccounts',
		},
		{
			value: 'Setting',
			function: () => {
				navigate('/setting');
			},
			icon: 'Settings',
		},
		{
			value: 'Logout',
			function: () => Logout(dispatch, LogOutSession),
			icon: 'Logout',
		},
	];

	const admin: any = [
		{
			value: 'Logout',
			function: () => Logout(dispatch, LogOutSession),
			icon: 'Logout',
		},
	];

	const option: any = {
		admin: admin,
		user: user,
	};

	return (
		<Header>
			<HeaderLeft>
				<div className='d-flex align-items-center'>
					<div className='row g-4'>
						<div className='col-md-auto'>
							<div
								className={classNames('fs-3', 'fw-bold', {
									'text-dark': !darkModeStatus,
								})}>
								<span className='text-capitalize'>
									{firstName} {lastName}
								</span>
							</div>
						</div>
					</div>
				</div>
			</HeaderLeft>
			<HeaderRight>
				<Dropdown>
					<DropdownToggle hasIcon={false}>
						<div
							className='bg-dark text-light rounded-circle d-flex justify-content-center cursor-pointer font-weight-bold align-items-center '
							style={{
								width: '50px',
								height: '50px',
								letterSpacing: '2px',
								fontSize: '24px',
							}}>
							<span className='text-uppercase'>
								{!!firstName && firstName[0]}
								{!!lastName && lastName[0]}
							</span>
						</div>
					</DropdownToggle>
					<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
						{option?.[role?.toLowerCase()]?.map((item: any) => {
							return (
								<DropdownItem>
									<Button icon={item?.icon} onClick={item?.function}>
										{item?.value}
										{item.value === 'Manage Billing' && loading && (
											<span style={{ marginLeft: '10px' }}>
												<Spinner isSmall inButton isGrow />
											</span>
										)}
									</Button>
								</DropdownItem>
							);
						})}
					</DropdownMenu>
				</Dropdown>
			</HeaderRight>
		</Header>
	);
};

export default DashboardBookingHeader;
