import Humans from '../../../../../assets/img/scene1NoBlur.webp'
import HumansWebp from '../../../../../assets/img/scene1NoBlur.webp';
import Button from '../../../../../components/bootstrap/Button';
import '../../../../../styles/pages/Report.scss';

const NoReportsPage = ({ path, name, value }: any) => {
	return (
		<div className='NoReportPage'>
			<div className='NoReportPageHeadingDiv '>
				<h1>{name}</h1>
			</div>
			<div className='NoReportPageImgDiv'>
				<img srcSet={HumansWebp} src={Humans} alt='Humans' />
			</div>
			<div className='NoReportPageButtonDiv '>
				<Button
					className='px-5 py-3'
					color='primary'
					isLight
					icon='QuestionAnswer'
					tag='a'
					to={path}>
					{value}
				</Button>
			</div>
		</div>
	);
};

export default NoReportsPage;
