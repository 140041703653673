import { Suspense } from 'react';
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';
import { Provider, useSelector } from 'react-redux';
import { store, persistor } from './store';
import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider,
	HttpLink,
	split,
	useMutation,
} from '@apollo/client';
import { PersistGate } from 'redux-persist/integration/react';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Logout } from './pages/presentation/auth/Logout';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import './styles/styles.scss';
import { getMainDefinition } from '@apollo/client/utilities';
import { MESSAGE, STATUS } from './reducer/Types';
import { error } from './helpers/constants';
import axios from 'axios';

const httpLink = new HttpLink({
	uri: process.env.REACT_APP_BASE_URL,
});

const wsLink = new GraphQLWsLink(
	createClient({
		url: process.env.REACT_APP_BASE_URL_WS as string,
	}),
);

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
	},
	wsLink,
	httpLink,
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (networkError?.message?.includes('Failed to fetch')) {
		let err = 'something went wrong!';
		store.dispatch({ type: MESSAGE, payload: err });
		store.dispatch({ type: STATUS, payload: false });
	} else {
		if (networkError?.message?.includes('401')) {
			Logout(store.dispatch, LogOutSession, { isNavigate: true });
		} else if (
			!!graphQLErrors?.length &&
			graphQLErrors &&
			//@ts-ignore
			!error?.includes(graphQLErrors[0])
		) {
			store.dispatch({
				type: MESSAGE,
				payload: graphQLErrors && graphQLErrors[0],
			});
			store.dispatch({ type: STATUS, payload: false });
		}
	}
});

const authLink = setContext((_, { headers }) => {
	const tokenLocalStorage =
		localStorage.getItem('persist:root') &&
		JSON.parse(localStorage.getItem('persist:root') || '');
	const user = JSON.parse(tokenLocalStorage?.user);
	return {
		headers: {
			...headers,
			authorization: user?.token ? `Bearer ${user?.token}` : '',
		},
	};
});

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: errorLink.concat(authLink.concat(splitLink)),
});

// This funtion is by Default set in main file issue in type script passing the props
const LogOutSession = async () => {
	const date = new Date();
	const apiUrl = process.env.REACT_APP_BASE_URL as string;

	const tokenLocalStorage = localStorage.getItem('persist:root');

	if (tokenLocalStorage) {
		try {
			const parsedLocalStorage = JSON.parse(tokenLocalStorage);
			const user = JSON.parse(parsedLocalStorage.user);
			if (user?.loggedInBy === 'user') {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: 'bearer ' + user.token,
					// Other headers if needed
				};

				const operationName = 'ExpireSession';
				const variables = {
					logoutTime: date.toISOString(),
					sessionId: user.sessionId,
					userId: user.id,
				};

				const query = `
  mutation ${operationName}($logoutTime: DateTime!, $sessionId: String!, $userId: String!) {
    expireSession(logoutTime: $logoutTime, sessionId: $sessionId, userId: $userId) {
      success
      message
    }
  }
`;
				axios
					.post(apiUrl, { query, variables }, { headers })
					.then((response) => {
						console.log('Logout successful', response.data);
						window.location.href = '/auth-pages/login';
					})
					.catch((error) => {
						console.log('Logout error', error);
					});
			}
		} catch (error) {
			console.error('Error parsing localStorage:', error);
		}
	}
};
// This funtion is by Default set in main file issue in type script passing the props

const children = (
	<AuthContextProvider>
		<ThemeContextProvider>
			<Suspense>
				<Provider store={store}>
					<PersistGate persistor={persistor}>
						<ApolloProvider client={client}>
							<BrowserRouter>
								<App />
							</BrowserRouter>
						</ApolloProvider>
					</PersistGate>
				</Provider>
			</Suspense>
		</ThemeContextProvider>
	</AuthContextProvider>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
