import React, { FC } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import Button from '../bootstrap/Button';
import Spinner from '../bootstrap/Spinner';

interface IModals {
	setOpen?: any;
	open?: any;
	title?: String;
	message?: String;
	deleted?: any;
	navigate?: any;
	icon?: string;
	text?: string;
	color?: boolean;
	loading?: boolean;
	isQuestionNaireModal?: boolean;
}

const Modals: FC<IModals> = ({
	setOpen,
	open,
	title,
	message,
	deleted,
	navigate,
	text,
	icon,
	color,
	loading,
	isQuestionNaireModal
}) => {
	return (
		<Modal
			// id={String}
			// titleId={String}
			isOpen={open} // Example: state
			setIsOpen={Function} // Example: setState
			// isStaticBackdrop={Boolean}
			isScrollable={open}
			isCentered={open}
			// width={isQuestionNaireModal ? '50vw' : ''}
			// height={isQuestionNaireModal ? '0vh' : ''}
			// size={'sm' || 'lg' || 'xl'}
			// fullScreen={'md'} // true || 'sm' || 'md' || 'lg' || 'xl' || 'xxl'
			isAnimation={open}>
			<ModalHeader
				navigate={navigate}
				// className={String}
				setIsOpen={setOpen} // Example: setState
			>
				<ModalTitle id=''>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody className={isQuestionNaireModal ? 'h4' : 'h6'}>{message}</ModalBody>
			<ModalFooter>
				<Button
					onClick={() => {
						setOpen(false);
						navigate && navigate('/reports');
					}}>
					Cancel
				</Button>
				<Button color={color ? 'info' : 'danger'} icon={icon} onClick={deleted}>
					{text}
					{loading && (
						<span style={{ marginLeft: '10px' }}>
							<Spinner isSmall inButton isGrow />
						</span>
					)}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default Modals;
