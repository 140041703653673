import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE_REPORT_TYPE } from '../../../../Api/Mutation';
import { useDispatch, useSelector } from 'react-redux';
import { adminTableHeader } from '../../../../LocalData/TableData';
import { GETALLREPORTSTYPE, MESSAGE, STATUS } from '../../../../reducer/Types';
import NoReportsPage from '../../reports-page/helper/noReportpage/NoReportsPage';
import Spinner from '../../../../components/bootstrap/Spinner';
import AdminTable from '../../../../components/table/adminTable/AdminTable';
import Modals from '../../../../components/modal/Modals';
import { GET_ALL_REPORTS_ADMIN } from '../../../../Api/Query';
import { useNavigate } from 'react-router-dom';

const AllReports = () => {
	const { user, getAllReportType, fetchErr } = useSelector((state: any) => state);
	const [open, setOpen] = useState(false);
	const [deleteId, setDeleteId] = useState<String>('');
	const navigate = useNavigate();
	const [deleteReportTypes] = useMutation(DELETE_REPORT_TYPE);
	const dispatch = useDispatch();
	const { data, loading, error } = useQuery(GET_ALL_REPORTS_ADMIN, {
		variables: { adminId: user?.id },
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (data !== undefined) {
			dispatch({ type: GETALLREPORTSTYPE, payload: data?.getAllReportTypes?.reportTypes });
		}
	}, [data]);

	const deleted = () => {
		deleteReportTypes({ variables: { deleteReportTypesId: deleteId } })
			.then(({ data }) => {
				if (!!data?.deleteReportTypes?.success) {
					const updateDelete = getAllReportType?.filter(
						(item: any) => item?.id !== deleteId,
					);
					dispatch({ type: GETALLREPORTSTYPE, payload: updateDelete });
					dispatch({ type: MESSAGE, payload: data?.deleteReportTypes.message });
					dispatch({ type: STATUS, payload: true });
					setOpen(false);
				} else {
					if (!data?.deleteReportTypes?.success) {
						setOpen(false);
						dispatch({ type: MESSAGE, payload: data?.deleteReportTypes.message });
						dispatch({ type: STATUS, payload: false });
						setOpen(false);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteList = (deleteId: String) => {
		setDeleteId(deleteId);
		setOpen(true);
	};

	const ReportTypes = (id: string) => {
		if (id) {
			navigate(`update-create-a-report-type/${id}`);
		}
	};

	return (
		<PageWrapper title='All-Reports'>
			<React.Fragment>
				{loading ? (
					<div className='d-flex align-items-center justify-content-center h-100'>
						<Spinner isGrow={true} color={'dark'} size={'5vh'} />
					</div>
				) : (
					<Page>
						{!!getAllReportType.length ? (
							<AdminTable
								GetReportsData={getAllReportType}
								tableHeader={adminTableHeader}
								heading='Reports List'
								deleteList={deleteList}
								ReportTypes={ReportTypes}
							/>
						) : (
							<NoReportsPage
								path='/Create-a-report-type'
								name='No report type exist!'
								value='Create report type'
							/>
						)}
					</Page>
				)}
			</React.Fragment>
			<Modals
				open={open}
				setOpen={setOpen}
				title='Delete Report Type?'
				message='Are you sure you want to delete the report type?'
				deleted={deleted}
				text='Delete'
				icon='delete'
			/>
		</PageWrapper>
	);
};

export default AllReports;
