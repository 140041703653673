import axios from 'axios';

export const getUserForSocialLogin = (googleToken: any, googleUserId: any) => {
	const apiUrl = process.env.REACT_APP_BASE_URL as string;
	const headers = {
		Authorization: 'bearer ' + googleToken,
	};

	const operationName = 'GetUser';
	const variables = {
		userId: googleUserId,
	};

	const query = `
	query ${operationName}($userId: ID!) {
		getUser(userId: $userId) {
			company {
				VAT
				address
				city
				companyName
				country
				createdAt
				deletedAt
				id
				regNumber
				updatedAt
				zipCode
			}
			plan {
				id
				name
				isYearly
				isMonthly
				planId
				noOfSimpleReports
				noOfAdvanceReports
				noOfStandardReports
				maxTokens
				ideaLength
				pdfWatermark
				newFeatures
				supportTeam
				apiAccess
				customWatermark
				createdAt
				deletedAt
				updatedAt
			}
			createdAt
			deletedAt
			firstName
			email
			id
			isSubscriptionActive
			isSuspended
			lastName
			planId
			stripeId
			updatedAt
			role
			preferences {
				emailYrSubscriptionRenew
				emailPlanUpgrade
				emailReport
			}
		}
	}
`;

	const config = {
		headers: headers,
	};

	return axios
		.post(apiUrl, { query, variables }, config)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			console.log('error', error);
		});
};
