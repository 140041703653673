import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { REPORTS_DEATAIL } from '../../../Api/Query';
import Spinner from '../../../components/bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import noReport from '../../../assets/svgImg/noReports.svg';
import { GETREPORTS } from '../../../reducer/Types';
import Page from '../../../layout/Page/Page';
import ReportIdeaHeader from '../../../components/report/reportIdeaHeader/ReportIdeaHeader';
import '../../../styles/pages/Report.scss';
import ToManyRequest from './helper/toManyRequest/ToManyRequest';
import ReportDetailsPDF from './ReportDetailsPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Icon from '../../../components/icon/Icon';

const HTMLResponse = ({ htmlString }: any) => {
	return <div className='reportMain' dangerouslySetInnerHTML={{ __html: htmlString }} />;
};
// font-size: calc(1.5rem + 1.5vw);
const ReportsDetail = () => {
	const { id, type }: any = useParams();
	const dispatch = useDispatch();
	const [reportStatus, setReportStatus] = useState<string | undefined>('');

	const { getReport, user, getAllReports } = useSelector((state: any) => state);

	const {
		data: queryData,
		loading: queryLoading,
		error: queryError,
	} = useQuery(REPORTS_DEATAIL, {
		variables: { reportId: id, userId: user?.id },
		pollInterval: reportStatus === 'GENERATING' ? 10000 : 0,
	});

	const [isloading, setIsLoading] = useState(true);
	const IdeaArr = JSON.parse(getReport?.businessIdea || '[]');
	const reportHtml = !!getReport?.stringifiedReport && JSON.parse(getReport?.stringifiedReport);
	const [htmlString, setHtmlString] = useState('');

	useEffect(() => {
		setHtmlString(reportHtml);
	}, [getReport?.stringifiedReport]);

	const idea = IdeaArr?.map((Item: any, i: Number, arr: any) => (
		<li
			style={{
				listStyle: arr?.length === 1 ? 'none' : 'auto',
				textAlign: arr?.length === 1 ? 'center' : 'start',
			}}>
			{Item}
		</li>
	));

	// useEffect(() => {
	// 	subscribeToMore({
	// 		document: REPORT_SUBCRIPTION,
	// 		variables: { id: id },

	// 		updateQuery: (prev, { subscriptionData }) => {
	// 			if (!subscriptionData.data) return prev;
	// 			const Item = subscriptionData.data;
	// 			if (Item) {
	// 				setIsLoading(false);
	// 				dispatch({ type: GETREPORTS, payload: Item?.reportGenerated });
	// 			}
	// 		},
	// 	});
	// }, [subscribeToMore]);

	useEffect(() => {
		setReportStatus(queryData?.getReport?.status);
		if (queryData?.getReport?.status === 'GENERATING') {
			setIsLoading(true);
		} else if (queryData?.getReport?.status === 'SUCCESS') {
			dispatch({ type: GETREPORTS, payload: queryData?.getReport });
			setIsLoading(false);
		} else if (queryData?.getReport?.status === 'PLAN_EXCEED') {
			setIsLoading(false);
			dispatch({ type: GETREPORTS, payload: [] });
		} else if (queryData?.getReport?.status === 'TOO_MANY_REQUESTS') {
			setIsLoading(false);
			dispatch({ type: GETREPORTS, payload: [] });
		}
		if (queryError?.graphQLErrors) {
			setReportStatus('ERROR');
			setIsLoading(false);
			dispatch({ type: GETREPORTS, payload: [] });
		}
	}, [queryData?.getReport, queryError?.graphQLErrors]);

	// useEffect(() => {
	// 	if (!status) {
	// 		setIsLoading(false);
	// 		dispatch({ type: GETREPORTS, payload: [] });
	// 	}
	// }, [message, status]);

	return (
		<PageWrapper>
			<React.Fragment>
				{isloading || queryLoading ? (
					<div
						className='d-flex align-items-center justify-content-center'
						style={{ height: '90vh' }}>
						<Spinner isGrow={true} color={'dark'} size={'5vh'} />
						<span style={{ paddingLeft: '10px' }}>
							Your report is generating, it may take from 30 seconds to couple of
							minutes to complete
						</span>
					</div>
				) : (
					<Page>
						{queryData?.getReport?.status === 'TOO_MANY_REQUESTS' ? (
							<ToManyRequest />
						) : (
							<div className='reportDetail'>
								<div className='reportDetailSheet'>
									<div className='reportDetailheadContainer'>
										<h1 className='reportDetailhead'>{type}</h1>
										{/* <PDFDownloadLink
											document={
												<ReportDetailsPDF
													type={type}
													IdeaArr={IdeaArr}
													idea={idea}
													htmlString={htmlString}
												/>
											}
											fileName={`${type}.pdf`}>
											<Icon
												icon='Download'
												size={'5x'}
												className='downloadIcon'
											/>
										</PDFDownloadLink> */}
									</div>
									<div
										className='shadow-lg  my-4  bg-white rounded d-block'
										style={{ padding: '2rem' }}>
										{!!IdeaArr?.length ? (
											<React.Fragment>
												<ReportIdeaHeader
													text={
														IdeaArr?.length === 1
															? 'Business Idea'
															: 'Your Questionnaire'
													}
													idea={idea}
												/>
												<HTMLResponse htmlString={htmlString} />
											</React.Fragment>
										) : (
											queryData?.getReport?.status === 'PLAN_EXCEED' && (
												<div className='Nojanerated'>
													<img src={noReport} alt='' />
													<h1>
														Plan Exceeded! Upgrade Now for Continued
														Access.
													</h1>
												</div>
											)
										)}
									</div>
								</div>
							</div>
						)}
					</Page>
				)}
			</React.Fragment>
		</PageWrapper>
	);
};

export default ReportsDetail;
