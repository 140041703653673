import '../../../styles/components/report/_reportIdeaheader.scss';

const ReportIdeaHeader = ({ text, idea }: any) => {
	return (
		<div className='ReportIdeaHeaderMain'>
			<h2>{text}</h2>
			<ol style={{ paddingLeft: '0' }}>{idea}</ol>
		</div>
	);
};

export default ReportIdeaHeader;
