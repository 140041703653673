import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { MESSAGE, STATUS } from '../../../../../reducer/Types';

export const s3Client = new S3Client({
	endpoint: 'https://fra1.digitaloceanspaces.com',
	region: 'eu-central-1',
	credentials: {
		accessKeyId: process.env.REACT_APP_DIGITAL_OCEAN_SPACE_ACCESS_KEY_ID as string,
		secretAccessKey: process.env.REACT_APP_DIGITAL_OCEAN_SPACE_SECRET_ACCESS_KEY as string,
	},
});

export const uploadFile = async ({ item, id, dispatch }: any) => {
	try {
		const params = {
			Bucket: 'ainova',
			Key: `${id}/${item.file.name}`,
			Body: item?.file,
			ACL: 'public-read',
		};
		await s3Client.send(new PutObjectCommand(params));
		const fileUrl = `https://ainova.fra1.digitaloceanspaces.com/${params.Key}`;
		return fileUrl;
	} catch (error: any) {
		dispatch({ type: MESSAGE, payload: error?.message });
		dispatch({ type: STATUS, payload: false });
	}
};
